.hide {
  display: none;
}

.issuedList .active {
  background: #518ced !important;
  color: #fff;
}

.issuedList .active p {
  color: #fff !important;
}

button.left {
  padding: 2px 8px;
  background: #99a3b1;
  border-radius: 3px;
  position: absolute;
  left: 12px;
  top: 12px;
  color: #fff;
}

// .p15 .fade:not(.show) {
//     opacity: 0;
//     display: none;
// }

.p15 .fade.listproducts-noimg input[type="checkbox"] {
  text-indent: -9999px;
  display: none;
}

#tooltip-2 {
  border: 1px solid #aac7ff;
  padding: 5px 10px;
  background-color: #f2f7ff;
  border-radius: 5px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
}

.blockfavoriteProcess ul li button {
  background: none;
}

.blockfavoriteProcess ul li button:not([disabled]) {
  color: #518ced;
}

.blockfavoriteProcess ul li.-active button {
  color: #fff;
}

.show {
  display: block !important;
}

figure.fade:not(.show) {
  opacity: 0;
  display: none;
}

.listbtnimg .-active,
#instructionCfr .blockBtn .-active {
  border: #3f51b5;
  background-color: #3f51b5;
}

.content_left .controlPanel {
  position: absolute;
  width: 125px;
  min-height: 50%;
  top: 48%;
  left: 0;
  list-style: none;
}

.content_left .control {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  flex-flow: column-reverse;
}

.content_left .controlPanel > li {
  position: relative;
  margin: 0;
  padding: 14px 0px;
  display: flex;
  justify-content: center;
}

.content_left .controlPanel > li > button {
  background: none;
}

.content_left .controlPanel > li > button > span:not(.cancel) {
  background: #5f5f5f;
  height: 52px;
  width: 52px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  border-radius: 100%;
}

.content_left .controlPanel > li > button > span.cancel {
  color: #fff;
}

.arrowDropdown::after {
  left: 8px;
  top: -5px;
}

.blue {
  color: #518ced;
}

.form-check-staff {
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex: 0 0 auto;
}

.form-check-staff label {
  width: 100%;
  /* border: 1px solid #5F646E; */
  border-radius: 16px;
  padding: 0 20px;
  height: 36px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.form-check-staff input[type="checkbox"] {
  display: none;
}

.form-check-staff .form-check-primary:disabled + label {
  background: none;
  border-color: #cdd5e5;
  color: #cdd5e5;
  cursor: not-allowed;
}

.form-check-staff .form-check-primary:checked + label {
  background: #518ced;
  border-color: #518ced;
  color: #fff;
}

.blockdatetime .dateSelect ul li.active {
  background: #cfd8e0;
  pointer-events: none;
}

.blockdatetime .datetime {
  cursor: pointer;
}

.filterDepartment {
  min-width: 1000px;
}

.btn-outline-danger:hover > span {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger.active > span {
  color: #fff;
}

div.rct-item {
  // height: 78px;
  display: flex;
  justify-content: flex-end;
  vertical-align: bottom;
  align-items: flex-end;
  padding: 6px 10px;
}

#tagConfirm .modal-dialog {
  max-width: 610px;
}

#tagConfirm table label.outline {
  border: 2px solid #000;
  padding: 1px 8px 0;
  background: #fff;
  margin-right: 5px;
  color: #000;
}

#tagConfirm .info .goal,
#tagConfirm .info .total {
  width: 80px;
}

// #tagConfirm table.tbUnmeasured td:first-child {
//   width: 240px;
// }

div.rct-item div.inside {
  overflow: hidden;
}

.rct-items .inside p.titleTop {
  overflow: hidden;
  text-overflow: ellipsis;
}

#instructionCfr .modal-body .blockRight .img {
  min-height: 400px;
}

#hiddenInput {
  .blockLeft .blockCard {
    pointer-events: none;
  }

  .ico.ico-del {
    display: none;
  }

  .number button img {
    opacity: 0.3;
    filter: grayscale(100%);
  }

  .blockImg .image img {
    object-fit: unset;
    width: auto;
    height: auto;
  }

  .btn-primary.f14:not(.mr10) {
    display: none;
  }
}

#instructionCfr {
  .blockCard_Desc-btnadd.dflex.dflex_end {
    display: none;
  }
}

.list_material {
  .listPhoto {
    .listPhotoFilter {
      padding-right: 10px;
    }
  }
}

.arrowSort.arrowRed {
  position: relative;
  display: inline-grid;
  height: auto;

  .arrowUp {
    position: absolute;
    right: 3px;
    top: 5px;
    border-bottom: 8px solid #ed5555;
  }
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0,
.react-calendar-timeline .rct-outer .rct-vertical-lines .rct-vl.rct-vl-first {
  border-color: #cdd5e5 !important;
  background: #f5f6fa !important;
  opacity: 0.5;
}

.timeLineEmployee .content {
  height: calc(100vh - 20px);
}

#taskProcess {
  span.ml05 {
    margin: 0px;
    font-size: 14px;
  }

  p.dflex {
    margin: 0px 0px 4px;
  }
}

.detailsChart {
  .noteMonth {
    font-weight: bold;
    font-size: 17px;
    position: absolute;
    left: 24%;
  }
}

@media print {
  body {
    padding: 0 20mm;
  }
}

@media print, screen {
  #PP-print {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .flexprint {
      width: 100%;
      // height: 100vh;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: space-between;
      align-items: flex-start;
      gap: 20px;

      table {
        width: 100%;
        table-layout: fixed;
        // height: 50vh;
        margin: 20vh 0;

        td {
          width: 33%;
          // border: 1px solid #000;
          padding: 5px 10px;
          word-break: normal;
          text-align: center;
          font-size: 16px;

          p {
            font-size: 16px;
          }

          .QRCode {
            height: 220px;

            svg {
              max-width: 100%;
              max-height: 220px;
            }
          }
        }

        .firstName {
          td {
            padding-bottom: 0;
          }
        }

        .lastName {
          td {
            padding-top: 0;
          }
        }
      }

      .itemP {
        overflow: hidden;
        height: calc(50vh - 20px);
        width: calc(100% - 20px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9),
        &:nth-child(13),
        &:nth-child(14),
        &:nth-child(15),
        &:nth-child(19),
        &:nth-child(20),
        &:nth-child(21) {
          justify-content: flex-end;
        }

        svg {
          width: 100%;
        }

        p {
          text-align: center;
        }

        // p:not(.noellipsis) {
        //   white-space: nowrap;
        //   overflow: hidden;
        //   text-overflow: ellipsis;
        // }
        .txt {
          flex: 0;
        }
      }
    }
  }
}

span.txtlable.active {
  border-color: #518ced;
  color: #518ced;
}

.arrowNE {
  position: relative;

  span.arrowNotEnough {
    // position: absolute;
    // top: 5px;
    // left: 1px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 8px solid #ed5555;
    cursor: pointer;
  }
}

.pflex {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  span {
    display: inline-block;
  }
}

.rightSidebar {
  .fixWi {
    width: 166%;
  }
}

.deleted {
  border: 1px solid #518cedab;
  background-color: #e9ecef;
}

div.timeSelect .isActive {
  background: #cfd8e0;
}

.fronGreen {
  color: #51c17d !important;

  span {
    color: #51c17d !important;
  }
}

.noBold {
  font-weight: normal !important;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.selectedEmployee .checked {
  background: #f8fbff !important;
  border: 2px solid #5a90d9 !important;

  span {
    color: #5a90d9 !important;
  }

  .authority {
    background: #5a90d9 !important;
    color: #fff !important;
  }
}

.text-wrapPP01 {
  width: 264px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.customTimeLine .rct-items .rct-item {
  border-left: 1px solid #fff !important;
}

.react-calendar-timeline {
  -webkit-user-select: none;
  user-select: none;
}

#errorTimeUndo {
  z-index: 1999;
}

.customTimeLine .rct-items .rct-item {
  z-index: 90 !important;
}

.modalAlert2 {
  .modal-dialog {
    justify-content: center;
  }

  .modal-content {
    width: inherit;
  }
}

.customTimeLine .rct-items .rct-item {
  padding: 2px 0px !important;
}

.customTimeLine .rct-items .rct-item {
  border-right: 1px solid #fff !important;
}

.customTimeLine .rct-items .notBorder {
  border-right: 1px !important;
  border-left: 1px !important;
}

.timeLineEmployee .content_inside {
  overflow-y: unset;
}

#OR-image {
  position: relative;
  overflow: hidden;
}

#orderImage .swipeable-list .swipeable-list-item {
  margin-bottom: 0px !important;
}

.liDisabled {
  background-color: #ccc !important;
  .btn-primary {
    background-color: #ccc;
    border-color: #ccc;
    pointer-events: none;
  }
}

.disabedLink {
  color: #ccc !important;
  border-color: #ccc !important;

  .btn-outline-primary {
    color: #ccc;
    border-color: #ccc;
  }
  pointer-events: none;
}

.content_inside.employeeDetailt .tbNohorizontal {
  max-height: 100% !important;
}

.selectstyle input:checked:disabled {
  background-color: #518ced !important;
  background: #518ced;
  opacity: 0.4 !important;
}

.disabedIcon {
  filter: grayscale(1);
  opacity: 0.3;
}

table td.postcode button:disabled,
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #dddfe5 !important;
  border-color: #dddfe5 !important;
  pointer-events: none;
}

.btnadd:disabled,
.btnminus:disabled,
.num.disabled {
  filter: grayscale(1);
  opacity: 0.3;
}

.arrRight {
  display: flex;
  background: none;
  justify-content: flex-end;
  img {
    transform: rotate(180deg);
  }
}

.wTile {
  color: #43425d;
  font-size: 21px;
  font-weight: bold !important;
}

.wCotent {
  margin-top: 10px;
  color: #141414;
}

.bloc15 {
  h2,
  p,
  div {
    text-align: center;
  }
  margin: auto;
  width: 500px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nonEven {
  cursor: text;
}
